/*@font-face {*/
/*    font-family: "mainFont";*/
/*    !*src: local('mainFont'), url('Assets/fonts/Abel-Regular.ttf') format('truetype');*!*/
/*}*/
*{
    /*font-family: "Open Sans", sans-serif;*/
    font-family: museo-sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif !important;
    margin: 0;
    font-weight: 400;
    padding: 0;
}